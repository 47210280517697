<template>
  <div v-if='options'>

    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <CanvasJSStockChart :options="options" :style="styleOptions" />
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import CanvasJS from '@canvasjs/stockcharts';
import globalController from "@/globalController.js";
import utils from "@/globalUtil.js";

export default {
  created() {
    this.loadData();
  },
  props: {
    full_ticker: String,
    title: {
      type: String,
      default: "---",
    },
    api_call: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isLoaded: false,
      chart: null,
      options: null,
      styleOptions: {
        width: "100%",
        height: "450px"
      }
    }
  },
  methods: {
    buildOptions(results) {
      var dps1 = [], dps2 = [], dps3 = [];

      let minimum_date = null;
      let maximum_date = null;

      if (!results.length) {
        return;
      }

      results.forEach(data => {
        let date = new Date(data["creation_date"] * 1000);
        if (minimum_date === null || date < minimum_date) {
          minimum_date = date;
        }
        // Update maximum_date
        if (maximum_date === null || date > maximum_date) {
          maximum_date = date;
        }

        dps1.push({ x: date, y: [data["open"], data["high"], data["low"], data["close"]] });
        dps2.push({ x: date, y: data["volume"] });
        dps3.push({ x: date, y: data["close"] });
      });

      let theme = "light2";
      let background_colour = "transparent";
      if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        theme = "dark1";        
      }
      
      this.options = {
        theme: theme,
        backgroundColor: background_colour,
        exportEnabled: false,
        title: {
          text: ""
        },
        charts: [{
          toolTip: {
            shared: true
          },
          axisX: {
            lineThickness: 5,
            tickLength: 0,
            labelFormatter: function (e) {
              return "";
            }
          },
          axisY: {
            prefix: "$"
          },
          legend: {
            verticalAlign: "top"
          },
          data: [{
            showInLegend: false,
            name: "Stock Price (in USD)",
            yValueFormatString: "$#,###.##",
            type: "candlestick",
            dataPoints: dps1
          }]
        }, {
          height: 100,
          toolTip: {
            shared: true
          },
          axisY: {
            prefix: "$",
            labelFormatter: this.addSymbols
          },
          legend: {
            verticalAlign: "top"
          },
          data: [{
            showInLegend: true,
            name: "Volume",
            yValueFormatString: "$#,###.##",
            dataPoints: dps2
          }]
        }],
        navigator: {
          data: [{
            dataPoints: dps3
          }],
          slider: {
            minimum: minimum_date,
            maximum: maximum_date
          }
        }
      }
    },
    async loadData() {
      this.isLoaded = false;

      let apiCall;
      if (this.full_ticker)
        apiCall = "/ticker/history/" + this.full_ticker;
      else
        apiCall = this.api_call;

      globalController.api_call(
        apiCall,
        (data) => {
          this.isLoaded = true;
          if (!data)
            return;

          this.buildOptions(data.results);
        },
        (error) => {
          console.log("Failed loading: Error loading related tickers");
          utils.runToast(
            ` ${error} `,
            "now-ui-icons ui-1_bell-53",
            "bottom-right",
            "danger"
          );
        }
      );
    },
    addSymbols(e) {
      var suffixes = ["", "K", "M", "B"];
      var order = Math.max(Math.floor(Math.log(Math.abs(e.value)) / Math.log(1000)), 0);
      if (order > suffixes.length - 1) order = suffixes.length - 1;
      var suffix = suffixes[order];
      return CanvasJS.formatNumber(e.value / Math.pow(1000, order)) + suffix;
    }
  }
}
</script>
