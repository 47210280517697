<template>
  <div v-if="isValidScore" class="star-rating">
    <!-- Full stars -->
    <i
      v-for="index in fullStars"
      :key="'full-' + index"
      class="fas fa-star star"
    ></i>

    <!-- Half star -->
    <i
      v-if="hasHalfStar"
      class="fas fa-star-half-alt star"
    ></i>

    <!-- Empty stars -->
    <i
      v-for="index in emptyStars"
      :key="'empty-' + index"
      class="far fa-star star"
    ></i>
  </div>
</template>

<script>
export default {
  name: 'StarRating',
  props: {
    /**
     * The score to be converted into stars (0-10)
     */
    score: {
      type: Number,
      required: true,
      default: 0,
    },
  },
  computed: {
    isValidScore() {
      const numericScore = Number(this.score);
      return !isNaN(numericScore) && numericScore >= 0 && numericScore <= 10;
    },
    // Number of full stars
    fullStars() {
      return Math.floor(this.score / 2);
    },
    // Whether to display a half star
    hasHalfStar() {
      return this.score / 2 % 1 >= 0.5;
    },
    // Number of empty stars
    emptyStars() {
      return 5 - this.fullStars - (this.hasHalfStar ? 1 : 0);
    },
  },
};
</script>

<style scoped>
.star-rating {
  display: flex;
  gap: 4px;
}

.star {
  color: rgb(146, 146, 146);
  font-size: 0.5rem; /* Adjust size as needed */
}
</style>
