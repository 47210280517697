<template>
  <div class="row">
    <div class="col-md-12">

      <vnud-card class="card card-article" :noFooterLine="true" v-if="isNothingHere">
        <template v-slot:header>
          <span class="pull-right image-padding" v-html="getImage('minion sad')"></span>
          <h4>😔 You don't have anything in your portfolio.</h4>
          <h4><i> Explore and watch companies! </i></h4>

        </template>
      </vnud-card>

      <vnud-card>
        <template v-slot:header>
          <h4 class="card-title ticker">COMPANY EXPLORATION</h4>
        </template>

        <collapse>
          <collapse-item v-for="(category, index) in categories" :key="index" :id="'cat' + index"
            @click="scrollToSection('cat' + index)">
            <template v-slot:title-raw>
              <i class='fal fa-star'></i>&nbsp;
              <span class="big_font">{{ category._id + '(' + category.count + ')' }}</span> &nbsp;
            </template>

            <div class="row">
              <div v-for="(sub_category, sub_index) in sub_categories[category._id]" :key="sub_index"
                :id="'sub' + sub_index">

                <CompanyRelated :api_call="getApiCallSubcategory(sub_category._id)"
                  :title="sub_category._id + ' [' + sub_category.count + ']'">
                </CompanyRelated>
              </div>
            </div>

          </collapse-item>
        </collapse>

      </vnud-card>
    </div>
  </div>
</template>
<style>
.ticker {
  text-transform: uppercase;
}
</style>
<script>
import { Collapse, CollapseItem } from "@/components";
import globalController from "@/globalController.js";
import utils from "@/globalUtil.js";
import CompanyRelated from "@/views/dashboard/company/CompanyRelated.vue";
import { routeLocationKey } from "vue-router";

export default {
  components: {
    Collapse,
    CollapseItem,
    CompanyRelated,
  },
  computed: {
    isNothingHere() {
      const queryParams = this.$route.query;
      return queryParams.nothingHere == "1"
    },
  },
  created() {
    const queryParams = this.$route.query;
    this.loadData(queryParams);
  },
  data() {
    return {
      name: "loading...",
      isLoaded: false,
      categories: [],
      sub_categories: {},
    };
  },
  watch: {

  },
  methods: {
    getImage(keywords) {
      try {
        return `
        <div style='border-radius: 15px; overflow: hidden; '>
          <video style='display:block; max-height: 220px;' width="400" autoplay loop muted playsinline onerror="this.remove();">
            <source src="/api/news/gif?keywords=${keywords}" type="video/mp4">
            Your browser does not support the video tag.
          </video>
          </div>`;

        //return "<img width='164px' src='/api/news/gif?keywords=" + gif_url + "'  onerror='this.remove();'>"
      } catch (e) {
        console.log("");
      }

      return "";
    },
    scrollToSection(my_section) {
      const section = document.getElementById("headingOne" + my_section);
      console.log(" Scroll to section " + my_section + " " + section);
      if (section) {
        setTimeout(() => {
          console.log(" Timeout Scroll to section " + my_section);

          /*
          if (section) {
            const offset = 80; // Adjust this value as needed to avoid overlapping
            const sectionPosition = section.getBoundingClientRect().top + window.scrollY - offset;
            window.scrollTo({
              top: sectionPosition,
              behavior: "smooth"
            });
          }
            */
          section.scrollIntoViewIfNeeded({ behavior: "instant", block: "start", inline: "nearest" });
        }, 100);
      }
    },
    getApiCall(category_name) {
      return "/company/query?gics_sector=" + encodeURIComponent(category_name);
    },
    getApiCallSubcategory(gics_sub_industry) {
      return "/company/query?only=company_name,exchange_tickers&gics_sub_industry=" + encodeURIComponent(gics_sub_industry);
    },
    async loadSubCategories(category) {
      globalController.api_call(
        "/company/categories?group=gics_sub_industry&gics_sector=" + encodeURIComponent(category),
        (data_result) => {
          this.sub_categories[category] = data_result.result;
          this.categories = this.categories.filter(category => category._id !== "NULL");
        },
        (error) => {
          console.log("Failed loading: Error loading suggestion");
        }
      );

    },
    async loadData(safe_name) {
      this.isLoaded = false;
      globalController.api_call(
        "/company/categories",
        (data_result) => {
          console.log("Categories result");
          this.isLoaded = true;
          this.categories = data_result.result;

          this.categories.forEach(category => {
            if (category._id === "") {
              category._id = "NULL";
              return
            }
            this.loadSubCategories(category._id);
          });
        },
        (error) => {
          console.log("Failed loading: Error loading suggestion");
          utils.runToast(
            ` ${error} `,
            "now-ui-icons ui-1_bell-53",
            "bottom-right",
            "danger"
          );
        }
      );
    },
  },
};
</script>
<style scoped>
.big_font {
  font-size: larger;
}
</style>
