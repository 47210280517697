<template>
  <vnud-card class="card-user">
    <template v-slot:header>

      <div v-if="user.subscription">
        <div class="pull-right"><small>Currently subscribed to: </small><br>
          <i class="now-ui-icons objects_diamond pull-right"></i>
          {{ user.subscription }}
        </div>
      </div>

      <h5 class="title">Edit Your AI Portfolio Feedback</h5>

    </template>

    <div>
      <p>Please provide a description or request for the AI to generate your personal news feed. </p>
      Try to be clear and specific so that the AI can better understand your needs.
      <!--
          Example:

          Instead of: "Tell me about dogs."
          Try: "Tell me about the different breeds of dogs and their characteristics."
          Instructions:
        -->

      Keep your prompt short and clear.
      Think about what you want the AI to do (e.g., answer a question, provide suggestions, generate ideas).
      If you're unsure, you can always ask for more specific help in our forums or discord channels.

    </div>
    <br>
    <checkbox v-model="use_markdown" class="pull-right no_top_fix">Format result &nbsp;</checkbox>

    <div class="row">
      <div class="margin_bottom">
        <div v-for="(sample, index) in samples" :key="sample" class="pull-left">
          <n-button @click="setSample(sample)" type="outline-primary" size="sm">
            <small>Example #{{ index + 1 }} [{{ sample.title.substring(0, 25) }}]</small>
          </n-button>
        </div>
      </div>
    </div>

    <QuillEditor :options="options" :toolbar="['bold', 'italic', { 'list': 'bullet' }]" v-model:content="promptContent"
      contentType="html" theme="snow" />

    <div class="row">
      <div class="col-md-4">
        <n-button @click="submitEdit()" type="default" size="sm">Test</n-button>
        <n-button @click="resetPrompt()" type="outline-default" size="sm">Reset
          Prompt</n-button>
      </div>
      <div class="col-md-8">
        <span>
          <el-select multiple class="select-info" size="small" v-model="company_selection" collapse-tags
            placeholder="Select market data">
            <el-option v-for="option in selects.portfolio_tickers" class="select-info" :value="option.value"
              :label="option.label" :key="option.label">
            </el-option>
          </el-select>
        </span>
      </div>
    </div>

    <n-button class="pull-right" @click="deleteAllPrompts()" type="danger" size="sm">Delete all prompts</n-button>

    <div v-if="!user.subscription">
      You don't have a subscription yet!.
      <br>
      <router-link to="/pricing?from=subscription_management">
        <i class="now-ui-icons objects_diamond"></i>&nbsp;
        <small>You can help us by buying a plan here!</small>
      </router-link>
    </div>

    <PleaseLogin />

  </vnud-card>

  <vnud-card class="card" v-if="logged_in">
    <div class="">
      <PromptList ref="promptListRef" @edit="editPrompt" />
    </div>
  </vnud-card>
</template>
<script>

import utils from "@/globalUtil.js";
import PromptList from "./PromptList.vue";
import globalController from "@/globalController.js";

import { mapState } from "vuex";
import { QuillEditor } from '@vueup/vue-quill'
import { Checkbox, Radio } from "@/components/index";
import { Switch, PleaseLogin } from "@/components";
import { ElSelect, ElOption } from "element-plus";

import '@vueup/vue-quill/dist/vue-quill.snow.css';

export default {
  name: "user-menu",
  created() {
    this.resetPrompt();
    this.loadSelectionData();
  },
  components: {
    Checkbox,
    PromptList,
    PleaseLogin,
    [Switch.name]: Switch,
    [ElOption.name]: ElOption,
    [ElSelect.name]: ElSelect,
    QuillEditor,
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
      logged_in: (state) => state.logged_in,
    }),
  },

  watch: {
    company_selection(inputString, oldValues) {
      // Remove from list the portfolio entries
      if (!inputString)
        return;

      //console.log(" COMPANY SELECTION NEW [" + inputString + "]      OLD  " + oldValues);

      const allowedRules = ["PORTFOLIO"];
      const rules = inputString.map(rule => rule.trim());

      if (this.ignore_next_change) {
        this.ignore_next_change = false;
        return;
      }

      // Someone is adding the portfolio      
      if (inputString.includes("PORTFOLIO"))
        if (!oldValues.includes("PORTFOLIO")) {
          // Check if PORTFOLIO exists in the list, and filter out any non-allowed rules

          let filtered = rules.filter(rule => allowedRules.includes(rule));
          if (filtered.length !== this.company_selection.length) {
            this.company_selection = filtered;
            this.ignore_next_change = true;
            return;
          }

          for (let i = 0; i < this.company_selection.length; i++)
            if (filtered[i] !== this.company_selection[i]) {
              this.company_selection = filtered;
              this.ignore_next_change = true;
              return;
            }

          return;
        } else {
          this.company_selection = inputString.filter(rule => rule != "PORTFOLIO");
          this.ignore_next_change = true;
        }
    }
  },
  methods: {
    deleteAllPrompts() {
      const userConfirmed = confirm("Are you sure you want to proceed?");
      if (!userConfirmed)
        return;

      let api_call = "/prompts/rm/ALL?type=" + this.prompt_type;
      globalController.api_call(
        api_call,
        (result) => {
          this.$refs.promptListRef.loadData();
        },
        (error) => {
          utils.runToast(
            ` Failed sending prompt ${error} `,
            "now-ui-icons ui-1_bell-53",
            "bottom-right",
            "danger"
          );
        }
      );
    },
    loadSelectionData() {
      if (!this.logged_in)
        return;

      let api_call = "/ticker/user/watchlist/get/default?";
      globalController.api_call(
        api_call,
        (result) => {

          result.exchange_tickers.forEach((symbol) => {
            this.selects.portfolio_tickers.push({ value: symbol, label: symbol },)
          });

          this.selects.portfolio = true;
          console.log("FINISHED LOADING");
        },
        (error) => {
          console.log("Failed loading: Error loading watchlist");
        }
      );
    },
    submitEdit() {
      // Logic to post the comment content to the API or handle locally

      let my_prompt = {
        'prompt': this.promptContent,
        'selection': this.company_selection,
        'type': this.prompt_type,
      };

      if (this.use_markdown)
        my_prompt['use_markdown'] = this.use_markdown;

      if (this.prompt_id)
        my_prompt['id'] = this.prompt_id;

      globalController.save_json_update(
        '/prompts/create',
        my_prompt,
        (data) => {
          if (data.status == "success") {
            /*
            utils.runToast(
              ` Sent `,
              "now-ui-icons ui-1_bell-53",
              "bottom-right",
              "info"
            );
            */

            this.$refs.promptListRef.loadData();
            return
          }

          utils.runToast(
            ` Failed sending prompt ${data.error_msg} `,
            "now-ui-icons ui-1_bell-53",
            "bottom-right",
            "danger"
          );
        },
        (error) => {
          utils.runToast(
            ` ${error} `,
            "now-ui-icons ui-1_bell-53",
            "bottom-right",
            "danger"
          );
        }
      )
    },
    editPrompt(child_prompt) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      this.promptContent = utils.markdown(child_prompt.prompt);
    },
    submitPrompt() {

    },
    setSample(sample) {
      this.promptContent = utils.markdown(sample.content);
      this.examplePrompt = sample.content;
    },
    resetPrompt() {
      let text = utils.markdown(this.examplePrompt);
      this.promptContent = text;
    },
  },
  data() {
    return {
      prompt_type: "PORTFOLIO",
      ignore_next_change: false,
      samples: [
        {
          'title': 'Simple prompt',
          'content': 'Given the articles related all to my portfolio companies, \nwhat is the overall state of the different sectors and give a sentiment about the current portfolio position in the stock market..',
        },
        {
          'title': 'AI Analyst',
          'content':
            `Welcome to your AI-powered stock market analysis! Your task is to provide an insightful, engaging, and data-driven analysis of the latest market trends and events, based on the following articles. Here’s what you need to focus on:

1. **Synthesize Key Information**: Highlight the most impactful events, trends, or data points across the articles, connecting them to broader market movements.
2. **Draw Actionable Insights**: Help readers understand the implications for investors. Who benefits? Who should be cautious?
3. **Include Intriguing Comparisons**: Where possible, draw comparisons between stocks, sectors, or indices to uncover patterns or divergences.
4. **Be Engaging**: Frame your response with a narrative style that captures the audience’s attention, avoiding heavy jargon while maintaining accuracy.
5. **Suggest Next Steps**: Offer recommendations or questions to consider for potential opportunities or risk management.

You’ll be working with articles like these:

* Article 0: Discusses mixed results in AI stock performance, with major players like Amazon and Google climbing, and Nvidia replacing Intel in the Dow Jones Industrial Average.
* Article 1: Reports a sharp downturn in the semiconductor index, detailing Nvidia's 9.5% drop.
* Article 2: Explores Nvidia’s recent market cap loss and alternative stocks like AMD and Broadcom.

Your response should be concise but rich in information, providing value to both experienced investors and curious newcomers. Now, dive into the data and craft an analysis that informs, intrigues, and inspires action!`
        },
        {
          'title': 'No bullshit',
          'content': 'The articles presented are related to the current state of my portfolio, write a bullshit to no bullshit state of the portfolio. '
        },
      ],

      use_markdown: true,
      prompt_id: null,
      company_selection: ["PORTFOLIO"],
      examplePrompt: "The articles presented are related to the current state of my portfolio, write a bullshit to no bullshit state of the portfolio.",
      promptContent: null,
      options: {
        toolbar: 'minimal',
        placeholder: 'Edit me...',
        theme: 'snow',
      },
      selects: {
        simple: "",
        portfolio_tickers: [
          { value: "PORTFOLIO", label: "Include all portfolio news" },
        ],
      },
    };
  },
  props: {
  },
};
</script>
<style scoped>
.user__menu ul.user-menu__nav {
  margin-top: 0;
  padding-top: 20px;
}

.card_body_limit {
  min-height: 100px;
}

.square_test_box {
  background-color: transparent;
  border: 1px solid #E3E3E3;
  border-radius: 5px;
  color: #2c2c2c;
  line-height: normal;
  height: auto;
  font-size: 0.8571em;
  transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
  box-shadow: none;

  padding-left: 15px;
  padding-right: 25px;
  margin-top: 5px;
  margin-left: 5px;
  margin-right: 15px;
}

.margin_local {
  margin: 15px;
}

.el-select {
  display: inline !important;
}
</style>
