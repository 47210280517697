<template>
  <Form @submit="onSubmit" :validation-schema="schema">
    <vnud-card>
      <template v-slot:header>
        <div>
          <h4 class="card-title">Login Form</h4>
        </div>
      </template>

      <vee-input
        name="fullName"
        type="text"
        placeholder="Full Name"
        label="Full Name"
      />

      <vee-input name="email" type="text" placeholder="Email or Username" label="Email or Username" />

      <vee-input
        name="password"
        type="text"
        placeholder="Password"
        label="Password"
      />

      <div class="d-flex justify-content-center">
        <n-button native-type="submit" type="primary">Login</n-button>
      </div>
    </vnud-card>
  </Form>
</template>
<script>
import { VeeInput } from "@/components";
import { Form } from "vee-validate";
import * as Yup from "yup";
import axios from "axios";

export default {
  components: {
    VeeInput,
    Form,
  },
  methods: {
    async submit() {
      try {
        let api = process.env.VUE_APP_API_URL + "/login";
        console.log(" API ENTRY " + api);

        const data = {
          username: this.email,
          password: this.password,
        };

        const response = await axios.post(api, data);

        // Store the user's token in local storage (or wherever you want to keep it)
        if (response.data.status == "error") {
          this.notify_error(response.data.error_msg);
          return;
        }

        localStorage.setItem("userToken", response.data.token);
        localStorage.setItem("userName", response.data.current_user);

        // Navigate to the dashboard (or wherever you want to send them after login)
        this.$router.push("/dashboard");
      } catch (error) {
        this.notify_error(error.message);
        console.error(error);
      }
    },
  },
  setup() {
    function onSubmit(values) {
      alert(JSON.stringify(values, null, 2));
    }

    const schema = Yup.object().shape({
      fullName: Yup.string().required().label("The Full Name"),
      email: Yup.string().email().required().label("The Email"),
      password: Yup.string().min(5).required().label("The Password"),
    });

    return {
      onSubmit,
      schema,
    };
  },
};
</script>
<style></style>
