<template>
  <div>
    <div class="row">
      <div class="col-md-6 ml-auto mr-auto text-center">
        <h2 class="title text-white">We're sorry to hear that you're considering canceling your subscription.</h2>
        <h5 class="description">
          If there's anything we can do to improve your experience, please let us know – your feedback is important to
          us!
        </h5>
      </div>
    </div>

  </div>
</template>

<script>
import globalController from "@/globalController.js";
import { mapState, mapActions } from "vuex";

export default {
  name: "vnud-pricing",
  data() {
    return {
      email: 'contact@engineer.blue',
      subject: 'Inquiry',
      body: 'Hello, I would like to inquire about tothemoon corporate...',
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
      logged_in: (state) => state.logged_in,
    }),
    mailtoLink() {
      const mailtoLink = `subject=${encodeURIComponent(this.subject)}&body=${encodeURIComponent(this.body)}`;
      return mailtoLink;
    },
  },
  methods: {
    addToCart(tier) {
      //debugger;
      if (!this.logged_in) {
        this.$router.push("/register?trial=" + tier);
        return;
      }

      globalController.api_call(
        "/payment/create_checkout_session?product=" + encodeURIComponent(tier),
        async (result) => {
          window.location.href = result.url;
        },
        (error) => {
          console.log("Failed loading: Error loading " + error);
        }
      );

    },
    contactUs() {
      this.$router.push("/contact?" + this.mailtoLink);
    },
  }
};
</script>

<style>
.text-white {
  color: #fff;
}
</style>

<style scoped>
a {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}
</style>