<template>
  <div class="form-check no_margin" :class="[{ disabled: disabled }, inlineClass]">
    <label :for="cbId" class="form-check-label">
      <input :id="cbId" class="form-check-input" type="checkbox" :disabled="disabled" v-model="model"
         />
      <span class="form-check-sign"></span>
      <slot>
        <span v-if="inline">&nbsp;</span>
      </slot>
    </label>
  </div>
</template>
<script>
export default {
  name: "checkbox-small",
  emits: ["update:modelValue", "change"],
  model: {
    prop: "modelValue",
  },
  props: {
    modelValue: [Array, Boolean],
    disabled: Boolean,
    inline: Boolean,
    hasError: Boolean,
  },
  data() {
    return {
      cbId: "",
      touched: false,
    };
  },
  computed: {
    model: {
      get() {
        return this.modelValue;
      },
      set(check) {
        if (!this.touched) {
          this.touched = true;
        }
        this.$emit("update:modelValue", check);
        this.$emit("change", check); // Emit a custom "change" event if needed
      },
    },
    inlineClass() {
      if (this.inline) {
        return `form-check-inline`;
      }
      return ``;
    },
  },
  created() {
    this.cbId = Math.random().toString(16).slice(2);
  },
};
</script>
<style scoped>
.no_margin {
  margin-top: 0px !important;
}
</style>
