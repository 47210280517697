<template>
  <div class="row">
    <div class="col-md-12">

      <div v-if="isLoaded && news_articles.length == 0" class="div_front">
        <vnud-card class="card card-article" :noFooterLine="true">
          <template v-slot:header>
            No articles found.
          </template>
        </vnud-card>
      </div>

      <div class="div_front">
        <span v-if="user.my_debug_interface" class="pull-right span_white">
          <a :href="getDebugApiEntry(apiCallFinal)" target="_blank">
            &nbsp;&nbsp;<i class="fa fa-gear"></i> OPEN API</a>
        </span>
        <div class="clearfix" />
      </div>
      <div v-if="news_articles.length > 0">
        <vnud-card class="card card-article" v-for="news in news_articles " :key="news" inverted :noFooterLine="true">

          <template v-slot:header>
            <span class='pull-right'><small><b class="dark_color ">{{ timeAgo(news.ai_upload_date) }}</b></small></span>

            <h4>
              <router-link v-if="companies" class="dark_link pointer" :to="gotoCompany(news.company_id)">
                <span class="" v-html="getTitle(news)"></span>
              </router-link>
            </h4>
            <CompanyStock :symbol="getSymbol(news.company_id)"></CompanyStock>

            <p v-html="markdown(news.ai_summary)">
            </p>

          </template>

          <template v-slot:footer>
            <StockChartRangeSelector :full_ticker="getSymbol(news.company_id)" />
            <!--
            <div class="row">
              <div class="col-md-12">
                <hr>
                <div class="action-items col-md-12">
                  <span class="action_item" :class="{ 'selected-class': currentCommentArticle === news.id }">
                    <a href="javascript:void(0)" @click="openCommentEditor(news)">
                      {{ news.no_comments }} Comments
                    </a>
                  </span>
                  <span class="action_item" :class="{ 'selected-class': currentShareArticle === news.id }">
                    <a href="javascript:void(0)" @click="openShareEditor(news.id)">
                      Share
                    </a>
                  </span>
                  <span class="action_item" :class="{ 'selected-class': currentReportArticle === news.id }">
                    <a href="javascript:void(0)" @click="openReportEditor(news.id)">
                      Report
                    </a>
                  </span>
                  <span class="" v-if="user.is_admin">
                    <CheckboxSmall :inline="true" v-model="news.is_blocked" @change="handleBlock(news)">Hide
                    </CheckboxSmall>
                  </span>
                </div>

                <span v-if="user.my_debug_interface" class="pull-right">
                </span>
                <div class="clearfix" />
              </div>                        
            </div>
-->
          </template>
        </vnud-card>
      </div>

      <div v-else>
        <br>
        <i v-if="!isLoaded" class="fa fa-2x fa-spinner fa-spin white" style='color:white'></i>
      </div>

    </div>
  </div>
</template>
<script>
import { useRoute } from 'vue-router';

import { TimeLine, MainItem, TimeLineItem, SharePanel } from "@/components";
import { Collapse, CollapseItem } from "@/components";
import CompanyStock from "@/views/dashboard/company/CompanyStock.vue";
import StockChartRangeSelector from "@/components/stockcharts/StockChartRangeSelector.vue";
import NewsComments from "@/views/dashboard/comments/NewsComments.vue";

import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';

import globalConfig from "@/globalConfig.js";
import globalController from "@/globalController.js";

import utils from "@/globalUtil.js";

import { mapState, mapActions } from "vuex";
import {
  CheckboxSmall, StarRating,
} from "@/components";

export default {
  components: {
    Collapse, CollapseItem,
    StockChartRangeSelector,
    CompanyStock,
    TimeLine,
    TimeLineItem,
    StarRating,
    SharePanel,
    MainItem,
    CheckboxSmall,
    NewsComments,
  },
  props: {
    symbol: String,
    apicall: String,
    disable_load: Boolean,
  },
  watch: {
    apicall(newValue) {
      this.news_articles = [];
      this.loadData();
    },
    symbol(newValue) {
      this.news_articles = [];
      this.loadData();
    },
  },
  created() {
    this.loadData();
    window.addEventListener('scroll', this.handleScroll);
  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll); // Clean up the event listener
  },
  updated() {
  },
  data() {
    return {
      newCommentContent: "",
      currentPage: 0,
      hasMoreData: true,
      pageSize: 10,    // Number of items per page
      ticker: "",
      isLoaded: false,
      news_articles: [],
      companies: {},
      apiCallFinal: null,
    };
  },
  computed: {
    isPortfolioPage() {
      return (this.$route.path === '/news/my_portfolio')
    },
    ...mapState({
      user: (state) => state.user,
      logged_in: (state) => state.logged_in,
    }),
  },
  methods: {
    timeAgo(datetime) { return utils.timeAgo(datetime) },
    getDebugApiEntry(apiCall) {
      return globalConfig.getApiPath(apiCall);
    },
    handleScroll() {
      // Only load when we are already loaded
      if (!this.isLoaded)
        return;

      if (this.disable_load)
        return;

      const scrollY = window.scrollY || window.pageYOffset;
      const visible = document.documentElement.clientHeight;
      const pageHeight = document.documentElement.scrollHeight;

      // If the user is within 300px of the bottom of the page and more data is available, load next page    
      if (scrollY + visible >= pageHeight - 300 && this.hasMoreData) {
        this.currentPage++;
        this.loadData(); // Load the next page
      }
    },
    gotoCompany(company_id) {
      let company= this.companies[company_id];
      if (!company)
        return;
      
      return "/company/view?id=" + company_id;
    },
    getSymbol(company_id) {
      let company= this.companies[company_id];
      if (!company)
        return;

      return company['exchange_tickers'][0];      
    },
    gotoTicker(company_id) {
      let company= this.companies[company_id];
      if (!company)
        return;

      let ticker = company['exchange_tickers'][0];
      return "/company/view?symbol=" + ticker;      
    },
    getTitle(article) {
      try {
        let c = this.companies[article.company_id];
        return c.company_name;
      } catch (e) {
      }
      return ""
    },

    markdown(src) {
      if (!src)
        return "";

      return utils.markdown(src);
    },
    nothingHere() {
      if (this.$route.path === '/news/my_portfolio')
        this.$router.push("/company/explore?nothing_here=1");
    },
    async loadData() {
      this.isLoaded = false;
      const queryParams = this.$route.query;

      let apiCall = "";

      const currentScrollPosition = window.scrollY || window.pageYOffset;

      // Does the component specify an API call?
      if (this.apicall) {
        apiCall = this.apicall;
      } else {
        if (queryParams.id) {
          apiCall = "/company/query_prompts?company_id=" + queryParams.id + "&order_by=-ai_upload_date&only=ai_summary,ai_upload_date,company_id&extra=include_company"
        } else {
          apiCall = "/company/latest_prompts?"
        }
      }

      apiCall += `&skip=${this.currentPage * this.pageSize}&limit=${this.pageSize}`;

      let page = this.currentPage;

      this.apiCallFinal = apiCall;

      globalController.api_call(
        apiCall,
        (result) => {
          this.companies = { ...result.companies, ...this.companies }
          
          if (result.news.length == 0) {
            this.hasMoreData = false;
            if (this.news_articles.length == 0)
              this.nothingHere();

          } else {
            this.news_articles = [...this.news_articles, ...result.news];
          }

          this.isLoaded = true;

          console.log("Result " + page);
        },
        (error) => {
          console.log("Failed loading: Error loading");
          utils.runToast(
            ` ${error} `,
            "now-ui-icons ui-1_bell-53",
            "bottom-right",
            "danger"
          );
        }
      );
    },
  }
};
</script>
<style>
.loading-spinner {
  display: flex;
  justify-content: center;
  padding: 20px;
}

.image-padding {
  padding-left: 10px;
}

.action-items {
  display: flex;
  gap: 16px;
  /* Controls spacing between each action item */
}

.action-item {
  padding-left: 10px;
  /* Adjust as needed for spacing */
}

.action-item a {
  text-decoration: none;
  color: #007bff;
  /* Customize color as needed */
  cursor: pointer;
}

.action-item a:hover {
  text-decoration: underline;
}

.selected-class a {
  color: #007bff !important;
}

.div_front {
  z-index: 9000;
  width: 100%;
}

.span_white a {
  color: #fff !important;
}

.dark_color {
  color: #555 !important;
}

.top_padding {
  padding-top: 15px;
}

.news_link:hover {
  cursor: pointer;
  text-decoration: underline;
}

.comment_editor {
  padding-top: 5px;
}

.div_timeago {
  min-width: 200px !important;
  overflow: hidden;
}

.card-article {
  padding: 4px;
  margin-top: 20px;
}

.card {
  max-width: 100vw;
  overflow: clip;
  /* add scrolling if content overflows */
}

.news_link {
  max-width: 100vw;
  overflow: clip;
  /* add scrolling if content overflows */
}

.local-margin-left {
  margin-left: 5px;
  margin-top: 5px
}

video {
  display: block;
}

.white {
  color: white;
}

@keyframes sirenToggle {

  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }
}

.siren-on {
  animation: sirenToggle 2s infinite;
}

.siren-off {
  animation: sirenToggle 2s infinite;
  animation-delay: 1s;
  /* Starts the second icon after the first */
}

.siren-positive {
  color: #58e4ac;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: rgb(116, 116, 116);
}

.siren-negative {
  color: red;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: rgb(126, 126, 126);
}

.siren-alert {
  position: absolute;
  top: 10px;
  left: 10px;
}
</style>
