<template>
  <div class="comment-div comment-plain">
    <div :id="'headingOne' + id + 1" class="comment-header">
      <a data-toggle="collapse" :data-target="'#headingOne' + id" :aria-expanded="active"
        :aria-controls="`content-${id}`" class="pointer">
        [-]
      </a>            
      <slot name="title">
        {{ title }}
      </slot>
    </div>

    <div :id="'headingOne' + id" :data-parent="'#' + parent_id" :aria-labelledby="'headingOne' + id + 1"
      :class="{ show: active }" role="tabpanel" class="collapse">
      <div class="comment-body">
        <slot></slot>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "comment-item",
  props: {
    comment: {
      type: Object,
      default: () => ({})
    },
    parent_id: {
      type: String,
      default: "accordionExample",
    },
    title: {
      type: String,
      default: "",
    },
    active: {
      type: Boolean,
      default: false,
    },
    id: String,
  },
  inject: {
    addItem: {
      default: () => { },
    },
    removeItem: {
      default: () => { },
    },
  },
  mounted() {
    this.addItem(this);
  },
  unmounted() {
    if (this.$el && this.$el.parentNode) {
      this.$el.parentNode.removeChild(this.$el);
    }
    this.removeItem(this);
  },
};
</script>
<style>
.comment-div {
  border: 0;
  display: inline-block;
  position: relative;
  width: 100%;
  margin-bottom: 5px;

  .card-body {
    padding: 5px 5px 10px 15px;

    &.table-full-width {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .card-header {
    &:not([data-background-color]) {
      background-color: transparent;
    }

    padding: 15px 5px 5px 5px 0;
    border: 0;

    .card-title {
      margin-top: 5px;
    }
  }

  .map {
    border-radius: $border-radius-small;

    &.map-big {
      height: 400px;
    }
  }

  .image {
    overflow: hidden;
    height: 200px;
    position: relative;
  }

  .avatar {
    width: 30px;
    height: 30px;
    overflow: hidden;
    border-radius: 50%;
    margin-bottom: 15px;
  }

  label {
    margin-bottom: 5px;
  }

  .card-footer {
    background-color: transparent;
    border: 0;

    .stats {
      i {
        margin-right: 5px;
        position: relative;
        top: 2px;
      }
    }

    .btn {
      margin: 0;
    }
  }
}

.comment-body {
  margin-left: 20px;
  margin-top: 5px;
}

.comment-plain {
  background: transparent;
  box-shadow: none;

  .card-header,
  .card-footer {
    margin-left: 0;
    margin-right: 0;
    background-color: transparent;
  }

}
</style>
