<template>
  <br>
  <div class="about-page">
    <!-- Hero Section -->
    <section class="hero-section text-center">
      <h1>HEADING TO MARS</h1>
      <p>Your gateway to real-time market insights and informed investment decisions.</p>
    </section>

    <!-- Mission Section -->
    <section class="mission-section">
      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <h2>Our Mission</h2>
            <p>
              At HeadingToMars.com, our mission is to empower investors with accurate, real-time market data, expert insights, and personalized recommendations to help them navigate the stock market confidently.
            </p>
          </div>
          <div class="col-md-6">
            <img src="/img/mission_portrait.webp" alt="Mission Image" class="img-fluid" />
          </div>
        </div>
      </div>
    </section>

    <!-- Features Section -->
    <section class="features-section">
      <div class="container">
        <h2 class="text-center">What We Offer</h2>
        <div class="row">
          <div class="col-md-4">
            <div class="feature-card">
              <i class="now-ui-icons media-2_sound-wave"></i>
              <h5>Real-Time Data</h5>
              <p>Stay updated with live market data and stock trends that matter to your portfolio.</p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="feature-card">
              <i class="now-ui-icons education_atom"></i>
              <h5>Personalized Insights</h5>
              <p>Receive tailored insights based on your investments to maximize your returns.</p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="feature-card">
              <i class="now-ui-icons objects_diamond"></i>
              <h5>Expert Analysis</h5>
              <p>Get access to professional analysis, sector reports, and predictions from financial experts.</p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Team Section -->
    <section class="team-section">
      <div class="container">
        <h2 class="text-center">Meet Our Team</h2>
        <div class="row">
          <div class="col-md-3" v-for="member in teamMembers" :key="member.id">
            <div class="team-card text-center">
              <img :src="member.photo" :alt="member.name" class="rounded-circle" />
              <h5>{{ member.name }}</h5>
              <p>{{ member.role }}</p>
              <p class="team-description">{{ member.description }}</p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Contact Section -->
    <section class="contact-section">
      <div class="container text-center">
        <h2>Get in Touch</h2>
        <p>
          Have questions or want to learn more? Reach out to us at
          <a href="mailto:info@tothemoon.life" class="contact-link">info@tothemoon.life</a>.
        </p>
        <n-button type="primary" round>Contact Us</n-button>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "AboutPage",
  data() {
    return {
      teamMembers: [
        {
          id: 1,
          name: "Alice Johnson",
          role: "CEO & Founder",
          photo: "/img/alice.png",
          description: "With over 15 years of experience in finance, Alice leads our mission to bring transparency and data-driven insights to investors.",
        },
        {
          id: 2,
          name: "Robert Lee",
          role: "Investment Analyst",
          photo: "/img/robert.png",
          description: "Robert specializes in AI-driven data models that analyze stock trends and provide actionable insights.",
        },
        {
          id: 3,
          name: "Nikki Koh",
          role: "Chief Data Scientist",
          photo: "/img/nikki.png",
          description: "Nikki is a quantitative researcher and genAI startup founder. She started her career as an investment analyst in disruptive tech hedge funds in both Singapore and London. Nikki is a programmer with deep learning skills.",
        },
        {
          id: 4,
          name: "Abraham Rodriguez",
          role: "Software Architect",
          photo: "/img/abraham.png",
          description: "As our lead software architect, Abraham designs scalable, secure platforms that provide seamless access to real-time data, ensuring a smooth user experience.",
        },

        // Add more team members here
      ],
    };
  },
};
</script>

<style scoped>
.about-page {
  padding: 20px 0;
  border-radius: 25px;
  background-color: #1c1a1ab5;
  color: #fff;
}
.hero-section h1 {
  font-size: 2em;
  color: #fff;
}
.hero-section p {
  font-size: 1em;
  color: #fff;
}
.mission-section, .features-section, .team-section, .contact-section {
  padding: 30px 0;
}
h2 {
  font-size: 1.5em;
  color: #fff;
}
p {
  font-size: 0.9em;
}
.feature-card, .team-card {
  background: #111;
  padding: 15px;
  border-radius: 6px;
  color: #ddd;
}
.feature-card i, .team-card img {
  font-size: 2.5em;
  color: #00d1b2;
}
.contact-link {
  color: #00d1b2;
  text-decoration: underline;
}
</style>
