<template>
  <div class="row">
    <div class="col-lg-8 col-md-7">
      <edit-profile-form> </edit-profile-form>
    </div>
    <div class="col-lg-4 col-md-5">
      <user-card> </user-card>
    </div>
    <div class="col-lg-8" col-md-7>
      <subscription-card> </subscription-card>
    </div>

  </div>
</template>
<script>
import EditProfileForm from "./UserProfile/EditProfileForm.vue";
import UserCard from "./UserProfile/UserCard.vue";
import SubscriptionCard from "./UserProfile/SubscriptionCard.vue";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    EditProfileForm,
    UserCard,
    SubscriptionCard,
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
      logged_in: (state) => state.logged_in,
    }),
  },
};
</script>
<style></style>
