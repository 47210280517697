import utils from "@/globalUtil.js";

const GlobalConfig = {
    getApiPath(relative_path = "") {
        const token = localStorage.getItem("userToken");

        let url = process.env.VUE_APP_API_URL + relative_path;
        if (token)
            url = utils.updateQueryParameter(url, "key", token);
        return url;
    },
    isDevelopment() {
        return (process.env.VUE_APP_API_URL.includes('dev.'));
    }    
}

export default GlobalConfig;