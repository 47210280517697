<template>
  <div>
    <div class="row">
      <div class="col-md-6 ml-auto mr-auto text-center">
        <h2 class="title text-white">Pick the best plan for you</h2>
        <h5 class="description">
          Each package includes Free Unlimited Updates and Premium Support.
        </h5>
      </div>
    </div>

    <div class="row">
      <!-- Patreon Plan -->
      
      <div class="col-lg-3 col-md-6">
        <vnud-card class="card-pricing" dark no-footer-line category="Patreon Plan">
          <div class="card-icon icon-warning">
            <i class="now-ui-icons objects_spaceship"></i>
          </div>
          <h3 class="card-title">$1/month</h3>
          <ul>
            <li>I want to help as a Patreon!</li>
            <li>We can buy beer!</li>
            <li>Extra perks every month tbd</li>
          </ul>
          <template v-slot:footer>
            <n-button type="primary" round @click="addToCart('tier1_monthly')">Buy</n-button>
          </template>
        </vnud-card>
      </div>

      <!-- Basic Plan -->
      <div class="col-lg-3 col-md-6">
        <vnud-card class="card-pricing" dark no-footer-line category="Basic Plan">
          <div class="card-icon icon-warning">
            <i class="now-ui-icons business_globe"></i>
          </div>
          <h3 class="card-title">$10/month</h3>
          <ul>
            <li>Weekly email summaries</li>
            <li>Weekly review of you portoflio</li>
            <li>Whatsapp and Telegram alerts</li>
          </ul>
          <template v-slot:footer>
            <n-button type="primary" round @click="addToCart('tier2_monthly')">Buy</n-button>
          </template>
        </vnud-card>
      </div>

      <!-- Standard Plan -->
      <div class="col-lg-3 col-md-6">
        <vnud-card class="card-pricing" dark no-footer-line category="Standard Plan">
          <div class="card-icon icon-primary">
            <i class="now-ui-icons objects_diamond"></i>
          </div>
          <h3 class="card-title">$29/month</h3>
          <ul>
            <li>Instant alerts</li>
            <li>Daily email updates</li>
            <li>Personal portfolio insights</li>
            <li>Whatsapp and Instagram alerts</li>
            <!-- <li>Insights into historical trends</li> -->
          </ul>
          <template v-slot:footer>
            <n-button type="primary" round @click="addToCart('tier3_monthly')">Buy</n-button>
          </template>
        </vnud-card>
      </div>

      <!-- Premium Plan 
      <div class="col-lg-3 col-md-6">
        <vnud-card class="card-pricing" plain no-footer-line category="Premium Plan">
          <div class="card-icon icon-success">
            <i class="now-ui-icons media-2_sound-wave"></i>
          </div>
          <h3 class="card-title">$49/month</h3>
          <ul>
            <li>Instant email alerts</li>
            <li>WhatsApp and Telegram notifications</li>
            <li>Priority access to exclusive insights</li>
          </ul>
          <template v-slot:footer>
            <n-button type="primary" round @click="addToCart">Add to Cart</n-button>
          </template>
        </vnud-card>
      </div>
      -->

      <!-- Enterprise Plan -->
      <div class="col-lg-3 col-md-6">
        <vnud-card class="card-pricing" dark no-footer-line category="Enterprise Plan">
          <div class="card-icon icon-danger">
            <i class="now-ui-icons education_atom"></i>
          </div>
          <h3 class="card-title">Custom Monthly Quote</h3>
          <ul>
            <li>Custom data access & analysis</li>
            <li>Direct analyst consultation</li>
            <li>API and bulk data access</li>
          </ul>
          <template v-slot:footer>
            <n-button type="neutral" round @click="contactUs">Contact Us</n-button>
          </template>
        </vnud-card>
      </div>
    </div>
  </div>
</template>

<script>
import globalController from "@/globalController.js";
import { mapState, mapActions } from "vuex";

export default {
  name: "vnud-pricing",
  data() {
    return {
      email: 'contact@engineer.blue',
      subject: 'Inquiry',
      body: 'Hello, I would like to inquire about tothemoon corporate...',
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
      logged_in: (state) => state.logged_in,
    }),
    mailtoLink() {
      const mailtoLink = `subject=${encodeURIComponent(this.subject)}&body=${encodeURIComponent(this.body)}`;
      return mailtoLink;
    },
  },
  methods: {
    addToCart(tier) {
      //debugger;
      if (!this.logged_in) {
        this.$router.push("/register?trial=" + tier);
        return;
      }

      globalController.api_call(
        "/payment/create_checkout_session?product=" + encodeURIComponent(tier),
        async (result) => {          
          window.location.href = result.url;
        },
        (error) => {
          console.log("Failed loading: Error loading " + error);
        }
      );

    },
    contactUs() {
      this.$router.push("/contact?" + this.mailtoLink);
    },
  }
};
</script>

<style>
.text-white {
  color: #fff;
}
</style>

<style scoped>
a {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}
</style>