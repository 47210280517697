<template>
  <vnud-card class="card-user">
    <div class="timeline-heading">
      <slot name="header"></slot>
    </div>
    <div class="timeline-body" v-if="$slots.content">
      <slot name="content"></slot>
    </div>
    <div v-if="$slots.footer">
      <slot name="footer"></slot>
    </div>

  </vnud-card>

</template>
<script>
export default {
  name: "main-item",
  props: {
    inverted: Boolean,
  },
};
</script>
<style></style>
