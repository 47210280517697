<template>
  <div class="row">
    <div class="">

      <vnud-card class="card" v-if="logged_in" noFooterLine>
        <div class="margin">
          <AIChatList ref="promptListRef" @edit="editPrompt" :article_id="article_id" />
        </div>

        <div class="margin-control">
          <hr class="summary_hr" />

          <QuillEditor :options="optionsPrompt" v-model:content="promptContent"
            :toolbar="['bold', 'italic', { 'list': 'bullet' }]" contentType="html" theme="snow"
            @ready="onEditorReady" />

          <div class="row">
            <div class="container-fluid">
              <n-button class="pull-right" @click="clearAllChat()" type="danger" size="sm">Clear all chat&nbsp;
                <i class="fal fa-close"></i>
              </n-button>
              <n-button @click="submitEdit()" type="default" :disabled="promptContent?.length == 0" size="sm">Send
                &nbsp;<i class='fal fa-send fa-xs'></i></n-button>
            </div>
          </div>
        </div>
      </vnud-card>

      <vnud-card class="card-user">
        <template v-slot:header>
          <div v-if="user.subscription">
            <div class="pull-right"><small>Currently subscribed to: </small><br>
              <i class="now-ui-icons objects_diamond pull-right"></i>
              {{ user.subscription }}
            </div>
          </div>
          <h4 class="card-title">{{ title || 'No title provided' }} <span v-if="systemName">with {{ systemName }}</span>
          </h4>
        </template>

        <collapse :modelValue="show_system_editor">
          <collapse-item :id="'col_prompt_edit'" :active="show_system_editor">
            <template v-slot:title-raw>
              <span class="read-more" style="margin-left: 5px;">
                <i class='fal fa-user-robot fa-2x' /> &nbsp;
                Click here to edit the assistant.
              </span>
            </template>
            <template v-slot>
              <QuillEditor :options="options" v-model:content="systemContent" contentType="html" theme="snow" />
              <br>
              <div class="row">
                <div class="col-md-12">

                  <span style="float:left">
                    <div style='margin-left:15px'>
                      <span class="category">CHAT</span><br>

                      <n-switch v-model="selects.chat" color="blue">
                        <template v-slot:on><i class="now-ui-icons ui-1_check"></i></template>
                        <template v-slot:off><i class="now-ui-icons ui-1_simple-remove"></i></template>
                      </n-switch>
                    </div>
                  </span>

                  <span style="float:left">
                    <div style='margin-left:15px'>
                      <span class="category">PORTOFOLIO</span><br>

                      <n-switch v-model="selects.portfolio" color="blue">
                        <template v-slot:on><i class="now-ui-icons ui-1_check"></i></template>
                        <template v-slot:off><i class="now-ui-icons ui-1_simple-remove"></i></template>
                      </n-switch>
                    </div>
                  </span>

                  <span v-for="option in selects.portfolio_tickers" :key="option" style="float:left">
                    <div style='margin-left:15px'>
                      <span class="category">{{ option.label }}</span><br>

                      <n-switch v-model="option.selected" color="blue">
                        <template v-slot:on><i class="now-ui-icons ui-1_check"></i></template>
                        <template v-slot:off><i class="now-ui-icons ui-1_simple-remove"></i></template>
                      </n-switch>
                    </div>
                  </span>
                </div>
              </div>
            </template>
          </collapse-item>
        </collapse>
        <div class="col-lg-12 ">

          <span v-for="(sample, index) in samples" :key="sample" class="">
            <n-button @click="setSampleSystem(sample)" type="outline-primary" size="sm">
              <small>Agent #{{ index + 1 }} [{{ sample.title.substring(0, 25) }}]</small>
            </n-button>
          </span>
        </div>

        <div v-if="!user.subscription">
          You don't have a subscription yet!.
          <br>
          <router-link to="/pricing?from=subscription_management">
            <i class="now-ui-icons objects_diamond"></i>&nbsp;
            <small>You can help us by buying a plan here!</small>
          </router-link>
        </div>
        <PleaseLogin />
      </vnud-card>

    </div>
  </div>
</template>
<script>

import utils from "@/globalUtil.js";
import AIChatList from "./AIChatList.vue";
import globalController from "@/globalController.js";

import { mapState } from "vuex";
import { QuillEditor } from '@vueup/vue-quill'
import { Checkbox, Radio } from "@/components/index";
import { Switch, PleaseLogin } from "@/components";
import { ElSelect, ElOption } from "element-plus";
import { Collapse, CollapseItem } from "@/components";

import '@vueup/vue-quill/dist/vue-quill.snow.css';

export default {
  name: "user-menu",
  props: {
    showDebug: Boolean,
    article_id: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: "Your Portfolio Questions and Answers",
    },
  },
  created() {
    this.resetPrompt();
    this.loadSelectionData();
  },
  components: {
    Checkbox,
    AIChatList,
    PleaseLogin,
    Collapse, CollapseItem,
    [Switch.name]: Switch,
    [ElOption.name]: ElOption,
    [ElSelect.name]: ElSelect,
    QuillEditor,
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
      logged_in: (state) => state.logged_in,
    }),
  },

  watch: {
    'selects.portfolio': {
      handler(newVal, oldVal) {
        console.log('portfolio changed ', { newVal, oldVal });
        if (!newVal)
          return newVal;

        this.isMutating = true;
        this.selects.portfolio_tickers.forEach((value) => {
          value.selected = false;
        });
      },
    },
    'selects.chat': {
      handler(newVal, oldVal) {
        console.log('chat changed ', { newVal, oldVal });
      },
    },
    'selects.portfolio_tickers': {
      handler(newVal, oldVal) {
        console.log('PORTFOLIO CHANGED ');

        if (!this.isMutating)
          this.selects.portfolio = false;

        this.isMutating = false;
      },
      deep: true,
    },
  },
  methods: {
    clearAllChat() {
      const userConfirmed = confirm("Are you sure you want to proceed?");
      if (!userConfirmed)
        return;

      let api_call = "/prompts/rm/ALL?type=" + this.prompt_type
      if (this.article_id)
        api_call += "?article_id=" + this.article_id;

      globalController.api_call(
        api_call,
        (result) => {
          this.$refs.promptListRef.loadData();
        },
        (error) => {
          utils.runToast(
            ` Failed sending prompt ${error} `,
            "now-ui-icons ui-1_bell-53",
            "bottom-right",
            "danger"
          );
        }
      );
    },
    updateFromCompanySelection() {
    },
    loadSelectionData() {
      if (!this.logged_in)
        return;

      let api_call = "/ticker/user/watchlist/get/default?";
      globalController.api_call(
        api_call,
        (result) => {

          result.exchange_tickers.forEach((symbol) => {
            let selected = false;
            if (this.company_selection.includes(symbol))
              selected = true;

            this.selects.portfolio_tickers.push({ value: symbol, label: symbol, selected: selected },)
          });

          this.selects.portfolio = true;
        },
        (error) => {
          console.log("Failed loading: Error loading watchlist");
        }
      );
    },
    submitEdit() {
      debugger;
      // Logic to post the comment content to the API or handle locally      
      this.company_selection = this.selects.portfolio_tickers.filter(item => item.selected).map(item => item.value);

      if (this.selects.portfolio)
        this.company_selection.push("PORTFOLIO")

      if (this.selects.chat)
        this.company_selection.push("CHAT")

      let my_prompt = {
        'type': this.prompt_type,
        'prompt': this.promptContent,
        'system': this.systemContent,
        'system_name': this.systemName,
        'selection': this.company_selection,
      };

      if (this.use_markdown)
        my_prompt['use_markdown'] = this.use_markdown;

      // An article to perform an internal query;
      if (this.article_id)
        my_prompt['article_id'] = this.article_id;

      if (this.prompt_id)
        my_prompt['id'] = this.prompt_id;

      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: 'smooth'
      });

      this.promptContent = "";

      globalController.save_json_update(
        '/prompts/create',
        my_prompt,
        (data) => {
          if (data.status == "success") {
            this.$refs.promptListRef.loadData();
            window.scrollTo({
              top: document.body.scrollHeight,
              behavior: 'smooth'
            });

            return
          }

          utils.runToast(
            ` Failed sending prompt ${data.error_msg} `,
            "now-ui-icons ui-1_bell-53",
            "bottom-right",
            "danger"
          );
        },
        (error) => {
          utils.runToast(
            ` ${error} `,
            "now-ui-icons ui-1_bell-53",
            "bottom-right",
            "danger"
          );
        }
      )
    },
    onEditorReady(quill) {
      //quill.keyboard.addBinding({ key: ['Enter'], ctrlKey:true }, this.submitEdit());
      //quill.keyboard.addBinding({ key: ['Enter'], shiftKey:true }, this.submitEdit());
    },
    editPrompt(child_prompt) {
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: 'smooth'
      });

      this.promptContent = utils.markdown(child_prompt.prompt);
    },
    setSampleSystem(sample) {
      this.systemName = sample.title;
      this.systemContent = utils.markdown(sample.content);
      this.examplePrompt = sample.content;
      this.show_system_editor = true;
    },
    resetPrompt() {
      let text = utils.markdown(this.examplePrompt);
      this.promptContent = text;
    },
  },
  data() {
    return {
      prompt_type: "CHAT",
      isMutating: true,      
      show_system_editor: false,
      ignore_next_change: false,
      samples: [
        {
          'title': "Ray Dalio",
          'content': "You are Raymond Thomas Dalio, an American investor and hedge fund manager, who has served as co-chief investment officer of the world's largest hedge fund, Bridgewater Associates, since 1985. He founded Bridgewater in 1975 in New York. You wrote Principles"
        },
        {
          'title': 'Warren Buffett',
          'content': 'You are **Warren Buffett**.\n\nYou have plenty of investment experience.\nYou love reading and providing feedback on the current news.\n'
        },
        {
          'title': 'AI Analyst',
          'content':
            `Welcome to your AI-powered stock market analysis!\n\nYour task is to provide an insightful,\nengaging, and data-driven analysis of the latest market trends and events based on the articles.`
        },
        {
          'title': 'No bullshit',
          'content': 'The articles presented are related to the current state of my portfolio,\n\\Write a **bullshit** to **no bullshit** state of the portfolio.'
        },
        {
          'title': 'Benjamin Graham',
          'content': "You are Benjamin Graham, British-born American financial analyst, economist, accountant, investor and professor. Also known as the father of value investing and writer of the discipline's founding texts: Security Analysis (1934), and The Intelligent Investor (1949)"
        },
        {
          'title': 'Donald Trump',
          'content': "You are Donald John Trump (born June 14, 1946) an American politician, media personality, and businessman who served as the 45th president of the United States from 2017 to 2021. He won the 2024 presidential election as the nominee of the Republican Party and is scheduled to be inaugurated as the 47th president on January 20, 2025."
        }
      ],

      use_markdown: true,
      prompt_id: null,
      company_selection: ["CHAT"],
      examplePrompt: "",
      systemName: "",
      promptContent: "",
      systemContent: null,
      options: {
        "modules": {
          "toolbar": false
        },
        placeholder: 'Default system agent...',
        theme: 'snow',
      },
      optionsPrompt: {
        toolbar: "minimal",
        placeholder: 'Message <TOTHEMOON>.',
        theme: 'snow',
        modules: {
          keyboard: {
            bindings: {
              custom: {
                key: ['Enter'],
                shiftKey: true,
                handler: function (range, context) {
                  debugger;
                  console.log(" BINDING ")
                },
              },
            }
          },
        },
      },
      selects: {
        simple: "",
        chat: true,
        portfolio: true,
        portfolio_tickers: [],
      },
    };
  },
};
</script>
<style scoped>
.user__menu ul.user-menu__nav {
  margin-top: 0;
  padding-top: 20px;
}

.card_body_limit {
  min-height: 100px;
}

.square_test_box {
  background-color: transparent;
  border: 1px solid #E3E3E3;
  border-radius: 5px;
  color: #2c2c2c;
  line-height: normal;
  height: auto;
  font-size: 0.8571em;
  transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
  box-shadow: none;

  padding-left: 15px;
  padding-right: 25px;
  margin-top: 5px;
  margin-left: 5px;
  margin-right: 15px;
}

.margin_local {
  margin: 45px;
}

.margin-control {
  margin-left: 15px;
  margin-right: 15px;
}

.el-select {
  display: inline !important;
}
</style>
