<template>
  <div class="">
    <div class="comments_margin col-md-12" v-for="comment in comments" :key="comment">
      <collapse :accordeonId="'a_' + comment.id">
        <comment-item :comment="comment" :parent_id="'a_' + comment.id" :id="'c_' + comment.id" active>
          <template v-slot:title>
            <i class='fa fa-bug' v-if="comment.is_report">&nbsp;</i>
            <i :class="'fa fa-' + comment.subscription" v-if="comment.subscription">&nbsp;</i>
            <b class='color_username'>{{ comment.username }}</b>

            &nbsp;<i class='color_username'> {{ timeAgo(comment) }}</i>
          </template>

          <div v-html="getContent(comment)"></div>

          <div v-if="currentEditArticle == comment.id">
            <hr>
            Edit this article:
            <QuillEditor :options="options"
              :toolbar="['bold', 'italic', 'strike', 'link', { 'list': 'ordered' }, { 'list': 'bullet' }]"
              @textChange="handleTextChange" v-model:content="editCommentContent" contentType="html" theme="snow" />

            <n-button v-if="editCommentContent != ''" @click="submitComment(article.id, comment.id, true)"
              type="default" size="sm">Submit</n-button>

            <n-button v-if="editCommentContent != ''" @click="cancelComment()" type="outline-default"
              size="sm">Cancel</n-button>
          </div>

          <span class="action_item" v-if="!logged_in">
            <a href=" javascript:void(0)" @click="registerToReply(comment)">
              register to reply
            </a>
          </span>

          <span class="action_item" v-if="logged_in"
            :class="{ 'selected-class': currentCommentArticle === comment.id }">
            <a href="javascript:void(0)" @click="openReply(comment)">
              reply
            </a>
          </span>
          <span class="action_item" v-if="user.username == comment.username">
            &nbsp;|&nbsp;
            <a href="javascript:void(0)" @click="openEdit(comment)">
              edit
            </a>
          </span>
          <span class="action_item" v-if="user.username == comment.username">
            &nbsp;|&nbsp;
            <a href="javascript:void(0)" @click="openDelete(comment)">
              delete
            </a>
          </span>
          <span class="action_item" :class="{ 'selected-class': currentReportComment === comment.id }"
            v-if="user.username != comment.username">
            &nbsp;|&nbsp;
            <a href="javascript:void(0)" @click="openReport(comment)">
              report
            </a>
          </span>

          <div v-if="currentCommentArticle === comment.id || currentReportComment == comment.id">
            <hr>
            <div v-if="currentCommentArticle === comment.id">
              Your reply:
            </div>
            <div v-if="currentReportComment === comment.id">
              <i class="fa fa-bug"></i> Your report:
            </div>

            <!--
            <div v-if="!logged_in">
              <h6>
                <router-link class="link footer-link" to="/register">
                  <i class='fa fa-link'></i> Please click here to register and comment...
                </router-link>
              </h6>
            </div>
          -->

            <QuillEditor :options="options" v-if="logged_in"
              :toolbar="['bold', 'italic', 'strike', 'link', { 'list': 'ordered' }, { 'list': 'bullet' }]"
              @textChange="handleTextChange" v-model:content="newCommentContent" contentType="html" theme="snow" />

            <n-button v-if="newCommentContent != ''" @click="submitComment(article.id, comment.id)" type="default"
              size="sm">Submit</n-button>

            <n-button v-if="newCommentContent != ''" @click="cancelComment()" type="outline-default"
              size="sm">Cancel</n-button>

            <hr>
          </div>

          <news-comments :article="article" :parent_comment_id="comment.id"></news-comments>

        </comment-item>
      </collapse>
    </div>
  </div>
</template>
<script>

import { mapState, mapActions } from "vuex";

import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';

import globalController from "@/globalController.js";
import globalConfig from "@/globalConfig.js";
import utils from "@/globalUtil.js";

import { Collapse, CollapseItem, CommentItem } from "@/components";
import NewsComments from "@/views/dashboard/comments/NewsComments.vue";

export default {
  components: {
    QuillEditor, NewsComments,
    Collapse, CommentItem, CollapseItem,
  },
  props: {
    parent_comment_id: {
      type: String,
      default: "NULL",
    },
    article: {
      type: Object,
      default: () => ({ 'id': 'empty' }),
      required: false,
    },
    apicall: {
      type: String,
      default: null,
    }
  },
  created() {
    this.loadData();
  },
  beforeUnmount() {
  },
  data() {
    return {
      newCommentContent: "",
      editCommentContent: "",

      currentCommentArticle: null,
      currentEditArticle: null,      
      currentReportComment: null,

      options: {
        toolbar: 'essential',
        placeholder: 'Place a comment and remember to be human...',
        theme: 'snow',
      },
      currentPage: 0,
      pageSize: 250,
      isLoaded: false,
      comments: [],
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
      logged_in: (state) => state.logged_in,
    })
  },
  methods: {
    registerToReply(comment) {
      this.$router.push("/register?comment=" + comment.id);
    },
    timeAgo(comment) {
      return utils.timeAgo(comment.creation_date);
    },
    resetActions() {
      this.currentCommentArticle = null;
      this.currentEditArticle = null;
      this.currentReportComment = null;
    },
    cancelComment(comment) {
      this.resetActions();
    },
    openReply(comment) {
      if (this.currentCommentArticle == comment.id) {
        console.log(" Unload editor ");
        this.resetActions();
        return;
      }
      this.resetActions();
      this.currentCommentArticle = comment.id;
      console.log(" Load editor ");
    },
    openEdit(comment) {
      this.resetActions();

      this.editCommentContent = comment.content;
      this.currentEditArticle = comment.id;

      console.log(" edit comment ");
    },
    openReport(comment) {
      if (!this.logged_in)
        return this.$router.push("/register?report=" + comment.id);

      this.resetActions();

      this.currentReportComment = comment.id;
      console.log(" Report comment ");
    },
    openDelete(comment) {
      if (confirm("Are you sure you want to delete this item?")) {
        // Call your delete logic here
        this.deleteItem(comment);
      }
    },
    deleteItem(comment) {
      console.log(" Delete comment ");
      let my_comment = {
        'id': comment.id,
      };

      let apiCall = '/comments/delete';

      console.log(`Submitting comment to delete ${comment.id}:`);
      globalController.save_json_update(
        apiCall,
        my_comment,
        (data) => {
          if (data.status == "success") {
            utils.runToast(
              ` Saved `,
              "now-ui-icons ui-1_bell-53",
              "bottom-right",
              "info"
            );

            this.loadData();
            return
          }

          utils.runToast(
            ` Failed uploading comment ${data.error_msg} `,
            "now-ui-icons ui-1_bell-53",
            "bottom-right",
            "danger"
          );
        },
        (error) => {
          utils.runToast(
            ` ${error} `,
            "now-ui-icons ui-1_bell-53",
            "bottom-right",
            "danger"
          );
        }
      )

    },
    getContent(comment) {
      return utils.markdown(comment.content);
    },
    getDebugApiEntry(apiCall) {
      return globalConfig.getApiPath(apiCall);
    },
    openCommentEditor(commentId) {
      // Set the current article ID to show the editor for that article only
      this.resetActions();
      this.newCommentContent = ''; // Reset comment content each time

      this.currentCommentId = commentId;
    },
    submitComment(articleId, commentId, update) {
      // Logic to post the comment content to the API or handle locally

      let my_comment;

      let apiCall = "";

      if (!update) {
        my_comment = {
          'parent_obj': 'comment',
          'parent_obj_id': articleId,
          'parent_comment_id': commentId,
        };

        apiCall = '/comments/create';
        my_comment['content'] = this.newCommentContent;
      } else {
        my_comment = {
          'id': commentId,
        };

        apiCall = '/comments/update';
        my_comment['content'] = this.editCommentContent;
      }

      if (this.currentReportComment === commentId) {
        my_comment['is_report'] = true;
        this.currentReportComment = null;
      }

      console.log(`Submitting comment to comment ${commentId}:`, this.newCommentContent);
      globalController.save_json_update(
        apiCall,
        my_comment,
        (data) => {
          if (data.status == "success") {
            utils.runToast(
              ` Saved `,
              "now-ui-icons ui-1_bell-53",
              "bottom-right",
              "info"
            );

            this.loadData();
            return
          }

          utils.runToast(
            ` Failed uploading comment ${data.error_msg} `,
            "now-ui-icons ui-1_bell-53",
            "bottom-right",
            "danger"
          );
        },
        (error) => {
          utils.runToast(
            ` ${error} `,
            "now-ui-icons ui-1_bell-53",
            "bottom-right",
            "danger"
          );
        }
      )

      // Here, you would send `this.newCommentContent` to the API or handle it as needed
      // After submission, clear the editor
      this.currentCommentArticle = null;
      this.newCommentContent = '';
    },
    openLinkComments(article) {
      this.$router.push(globalController.get_news_link({ 'id': article.id, 'title': this.getNoBullshit(article) }))
    },
    openLink(article) {
      window.open(article.link);
    },
    getBadgeIcon(score_array) {
      return 'business_briefcase-24';
    },

    async loadData(company_name) {
      this.isLoaded = false;
      const queryParams = this.$route.query;

      let apiCall = "";

      // Does the component specify an API call?
      if (this.apicall) {
        apiCall = this.apicall;
      } else {

        if (!this.article)
          return;

        console.log(this.article);
        apiCall = "/comments/query?parent_obj_id=" + encodeURIComponent(this.article.id) + "&parent_comment_id=" + this.parent_comment_id;
      }

      apiCall += `&skip=${this.currentPage * this.pageSize}&limit=${this.pageSize}`;

      let page = this.currentPage;

      this.apiCallFinal = apiCall;

      globalController.api_call(
        apiCall,
        (result) => {
          if (result.comments.length == 0) {
            this.hasMoreData = false;
          } else {
            //this.comments = [...this.comments, ...result.comments];
            this.comments = result.comments;
          }

          this.isLoaded = true;

          console.log("Comments result: Result " + page);
        },
        (error) => {
          console.log("Failed loading: Error loading suggestion");
          utils.runToast(
            ` ${error} `,
            "now-ui-icons ui-1_bell-53",
            "bottom-right",
            "danger"
          );
        }
      );
    },
  }
};
</script>
<style scoped>
.loading-spinner {
  display: flex;
  justify-content: center;
  padding: 20px;
}

.image-padding {
  padding-left: 10px;
}

.action-items {
  display: flex;
  gap: 16px;
  /* Controls spacing between each action item */
}

.action-item {
  padding-left: 10px;
  /* Adjust as needed for spacing */
}

.action-item a {
  text-decoration: none;
  color: #007bff;
  /* Customize color as needed */
  cursor: pointer;
}

.action-item a:hover {
  text-decoration: underline;
}

.selected-class a {
  color: #007bff !important;
}

.div_front {
  z-index: 9000;
}

.span_white a {
  color: #fff !important;
}

.color_username {
  color: #777 !important;
  font-size: smaller;
}

.top_padding {
  padding-top: 15px;
}

.news_link:hover {
  cursor: pointer;
  text-decoration: underline;
}

.comments_margin {
  margin-top: 5px;
}

.action_item {
  font-size: smaller;
}
</style>
