<template>
  <!--
  <tr><td>
    <div style="width: 100%;background-color: blanchedalmond; height: 10px;"></div>
  </td></tr>
-->
  <tr>
    <td :class="{ blurred: task.status === 'deleted' }">
      <hr class="separator" />
      <div class="border-box">
        <div class="row">
          <table width="100%" style=''>
            <tbody>
              <tr>
                <td>
                  <div class="container">
                    <i class="fal fa-chevron-double-left"></i>&nbsp;
                    <i class="fal fa-user fa-2x"></i>
                    <b>&nbsp;
                      {{ task.username }}
                    </b>
                    <div>
                      <small style="float:left" class="margin-top-local">
                        <div v-html="markdown(task.prompt)"></div>
                      </small>
                    </div>
                  </div>
                </td>
                <td>
                  <div class="table-icons pull-right" v-if="task.status !== 'deleted'">
                    <div class="pull-right margin-right-local"><small>{{ timeAgo(task.creation_date) }}</small></div>
                    <br>
                    <button v-if="task.status !== 'deleted'" type="button" rel="tooltip" title="Edit Task"
                      class="btn btn-info btn-default btn-xs my_btn-xs" @click="handleEditClick">
                      <i class="fas fa-edit"></i>
                    </button>

                    <button v-if="showDebug" type="button" rel="tooltip" title="Open Api"
                      class="btn btn-info btn-default btn-xs my_btn-xs" @click="handleDebug">
                      <i class="fas fa-gear"></i>
                    </button>

                    <button type="button" rel="tooltip" title="Remove"
                      class="btn btn-danger btn-outline-default btn-xs my_btn-xs" @click="handleDeleteClick">
                      <i class="fas fa-close"></i>
                    </button>
                    <div class="clearfix" />
                  </div>
                  <div class="pull-right" v-if="task.status != 'PROCESSED'">{{ task.status }}</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <hr class="summary_hr">
        <div class="margin-top-local">
          <b>
            <i class="fal fa-chevron-double-right"></i> <i class="fal fa-robot fa-2x"></i> {{ task.system_name }}
          </b>
          <br>
        </div>

        <div v-if="task.ai_summary">
          <div v-html="markdown(task.ai_summary)" />
        </div>
        <div v-else>
          Thinking... <i class="fal fa-spin fa-spinner"></i>
          <small class="pull-right" v-if="task.ai_queue_size > 0">
            <span> Queue position #{{ task.ai_queue_size }}</span>
          </small>
        </div>

        <collapse v-if="task.raw" class="responsive-text">
          <collapse-item :id="'col_' + task.id">
            <template v-slot:title-raw>
              <span class="">
                <br>
                <span class=" pull-right">Show <b>HEADING_TO_MARS</b> memory...</span>
              </span>
            </template>
            <template v-slot>
              <div class="background-color-local">
                <div v-for="(task_role, index) in task.raw" :key="index">
                  <span><b>{{ task_role.role }}</b></span> <i class="fal fa-chevron-double-right"></i>
                  <p v-html="markdown(task_role.content)">
                  </p>
                </div>
              </div>
            </template>
          </collapse-item>
        </collapse>

      </div>
    </td>
  </tr>

</template>
<script>
import { Checkbox, Radio } from "@/components/index";
import { Collapse, CollapseItem } from "@/components";

import utils from "@/globalUtil.js";
export default {
  components: { Checkbox, Collapse, CollapseItem, },
  props: {
    showDebug: Boolean,
    task: {
      type: Object,
      default: () => {
        return {
          title: "",
        };
      },
    },
    index: String,
  },
  methods: {
    timeAgo(datetime) { return utils.timeAgo(datetime) },
    markdown(src) {
      if (!src)
        return "";

      return utils.markdown(src);
    },
    handleDebug() {
      this.$emit("on-debug", this.index);
    },
    handleEditClick() {
      this.$emit("on-edit", this.index);
    },
    handleDeleteClick() {
      this.$emit("on-delete", this.index);
    },
  },
};
</script>
<style scoped>
td {
  border-color: rgba(94, 94, 94, 0.11) !important;
}

.separator {
  width: 100%;
  margin-top: -5px;
  margin-bottom: 5px;
  border-top: 2px solid black;
}

.summary_hr {
  width: 100%;
  border: 1px dotted #999;
  border-style: none none dotted;
  margin-top: -2px;
  color: #afff;
  background-color: #fff;
}

.blurred {
  filter: blur(2px);
  opacity: 0.7;
  /* Optional: makes the blur more visible */
}

.table-icons {
  margin-right: 1px;
}

.my_btn-xs {
  margin-left: 15px !important;
  margin-right: 15px !important;
  margin-top: 5px !important;
}

.border-box {
  max-width: 100% !important;
}

.articles_container {
  overflow: hidden;
  /* Hide overflowing text */
  text-overflow: ellipsis;
  /* Add ellipsis (...) if text overflows */
  white-space: nowrap;
  /* Prevent text wrapping */
}

.responsive-text {
  max-width: 100%;
  /* Ensure text stays within container */
  word-break: break-word;
  /* Break words to prevent overflow */
}

.margin-top-local {
  margin-bottom: 5px;
}

.margin-right-local {
  margin-right: 15px;
}

.background-color-local {
  background-color: rgba(223, 223, 223, 0.384);
  border-radius: 35px;
  padding: 25px;
}
</style>
