<template>
  <Form @submit="onSubmit" :validation-schema="schema">
    <div class="col-md-6 ml-auto mr-auto">
      <vnud-card class="card-login card-dark" noFooterLine>
        <template v-slot:header>
          <div>
            <div class="logo-container">
              <img src="img/tothemoon-life-logo.png" alt="" />
            </div>
          </div>
        </template>

        <div>          
          <vee-input name="email" type="text" inputClasses="no-border form-control-lg" placeholder="Email or Username"
            addon-left-icon="fal fa-envelope fa-lg" />
          <br>
          <vee-input name="password" type="password" inputClasses="no-border form-control-lg" placeholder="Password"
            v-if="!isPasswordRecovery" addon-left-icon="fal fa-lock fa-lg" />
        </div>

        <template v-slot:footer>
          <div>
            <n-button native-type="submit" :type="isPasswordRecovery ? 'primary' : 'info'" round block>
              {{ isPasswordRecovery ? 'Recover Password' : 'Get Started' }}
            </n-button>

            <div class="distance-footer">
              <div class="pull-left">
                <h6>
                  <router-link class="link footer-link" to="/register">
                    Create Account
                  </router-link>
                </h6>
              </div>

              <div class="pull-right">
                <h6>
                  <a class="link footer-link pointer" @click="togglePasswordRecovery">
                    Password recovery
                  </a>
                </h6>
              </div>
            </div>
          </div>
        </template>
      </vnud-card>
    </div>
  </Form>
</template>
<script>
import { VeeInput } from "@/components";
import { Form } from "vee-validate";
import { ref, getCurrentInstance } from "vue";

import globalConfig from "@/globalConfig.js";
import utils from "@/globalUtil.js";

import * as Yup from "yup";
export default {
  name: "vnud-login",
  components: {
    VeeInput,
    Form,
  },
  methods: {},
  setup() {
    const { proxy } = getCurrentInstance();

    proxy.$store.commit("setLogout");

    const isPasswordRecovery = ref(false); // Track password recovery state

    function togglePasswordRecovery() {
      isPasswordRecovery.value = !isPasswordRecovery.value; // Toggle the state
    }

    function onSubmit(values) {

      const apiEndpoint = isPasswordRecovery.value
        ? "/user/password_recovery"
        : "/user/login";

      const requestBody = isPasswordRecovery.value
        ? { email: values.email } // Only email for password recovery
        : { email: values.email, password: values.password }; // Email and password for login

      fetch(globalConfig.getApiPath(apiEndpoint), {
        method: "post",
        mode: "cors",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.status != "success") {
            utils.runToast(
              ` ${data.error_msg} `,
              "now-ui-icons ui-1_bell-53",
              "bottom-right",
              "danger"
            );
            return;
          }

          if (isPasswordRecovery.value) {
            utils.runToast(
              `Password recovery instructions have been sent to ${values.email}`,
              "now-ui-icons ui-1_bell-53",
              "bottom-right",
              "info"
            );
          } else {
            localStorage.setItem("userToken", data.token);
            localStorage.setItem("userName", data.current_user);
            proxy.$store.dispatch("fetchUser");
            proxy.$router.push("/dashboard");
          }
        })
        .catch((error) => {
          console.log(error);
          utils.runToast(
            ` There was a problem accessing this server, please try again later. `,
            "now-ui-icons ui-1_bell-53",
            "bottom-right",
            "danger"
          );
        });
    }

    const schema = Yup.object().shape({
      email: Yup.string().required().label("The Email or Username"),
      password: Yup.string()
        .min(6)
        .when("isPasswordRecovery", {
          is: false, // Password is required only when not in recovery mode
          then: Yup.string().required().label("The Password"),
          otherwise: Yup.string().notRequired(), // Skip validation when in recovery mode
        }),
    });

    return {
      onSubmit,
      schema,
      isPasswordRecovery,
      togglePasswordRecovery,
    };
  },
};
</script>
<style scoped>
.distance-footer {
  margin-top: 10px;
}

.navbar-nav .nav-item p {
  line-height: inherit;
  margin-left: 5px;
}
</style>
