<template>
  <div class="col-md-4 ml-auto mr-auto">
    <vnud-card class="card-lock text-center" no-footer-line>
      <template v-slot:header>
        <div style='position:absolute; right:15px; cursor:pointer;'>
          <i class='fa fa-close fa-2x' @click="logout"></i>
        </div>
        <img :src="userImage" alt="..." />
      </template>

      <h4 class="card-title">{{ firstName }} <br> {{ lastName }}</h4>
      <fg-input v-model="password" type="password" placeholder="Enter New Password..">
      </fg-input>
      <template v-slot:footer>
        <div>
          <n-button type="primary" round wide @click="setNewPassword">Save New Password</n-button>
        </div>
      </template>

    </vnud-card>
  </div>
</template>

<script>
import globalController from "@/globalController.js";
import globalConfig from "@/globalConfig.js";
import utils from "@/globalUtil.js";

export default {
  name: "vnud-lock",
  data() {
    return {
      userImage: "/img/default-avatar.png",
      userName: "",
      firstName: "",
      lastName: "",
      password: "",
      isLoaded: false,
      token: "",
    };
  },
  created() {
    this.loadUserRecovery();
    const queryParams = this.$route.query;
    if (!queryParams.key) {
      this.token = queryParams.key;
    }
  },
  setup() {
  },
  methods: {
    async loadUserRecovery() {
      this.logout();

      this.isLoaded = false;
      const queryParams = this.$route.query;

      if (!queryParams.key) {
        let error = "Missing login credentials";
        utils.runToast(
          ` ${error} `,
          "now-ui-icons ui-1_bell-53",
          "bottom-right",
          "danger"
        );
        return;
      }

      debugger;
      let apiCall = "/user/token?key=" + queryParams.key;

      globalController.api_call(
        apiCall,
        (result) => {
          debugger;
          this.isLoaded = true;
          this.firstName = result.first_name;
          this.lastName = result.last_name;
          this.userName = result.username;
          console.log("User loaded ");

          localStorage.setItem("userToken", result.token);

          if (this.userName)
            localStorage.setItem("userName", this.userName);
        },
        (error) => {
          console.log("Failed loading: Error loading suggestion");
          utils.runToast(
            ` ${error} `,
            "now-ui-icons ui-1_bell-53",
            "bottom-right",
            "danger"
          );
        }
      );
    },
    logout() {
      console.log("LOCK: Remove user");
      localStorage.removeItem("userName");
      localStorage.removeItem("userToken");
      localStorage.removeItem("userData");
    },
    setNewPassword() {
      console.log("set new password button clicked");

      fetch(globalConfig.getApiPath("/user/set/password"), {
        method: "post",
        mode: "cors",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          value: this.password,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.status != "success") {
            utils.runToast(
              ` ${data.error_msg} `,
              "now-ui-icons ui-1_bell-53",
              "bottom-right",
              "danger"
            );
            return;
          }

          this.$router.push("/login");
        })
        .catch((error) => {
          utils.runToast(
            ` ${error} There was a problem accessing this server `,
            "now-ui-icons ui-1_bell-53",
            "bottom-right",
            "danger"
          );

          console.log(error);
        });
    },
  },
};
</script>

<style></style>
