<template>
  <div class="row">

    <div class="col-md-12">
      <vnud-card hideCardHeader>
        <template v-slot:header><i v-if="!isLoaded" class="pull-right fa fa-gear fa-spin"></i></template>

        <div class="" v-if="isFirstLoaded">
          <n-table class="table-shopping" :data="watchListTable">
            <template v-slot:columns>
              <th class="text-center"></th>
              <th>Symbol</th>

              <th>Company</th>
              <th class="text-right">Last</th>
              <th class="text-right">Chg</th>
              <th class="text-right">Chg%</th>
              <th class="text-right">Open</th>
              <th class="text-right">Day Low</th>
              <th class="text-right">Day High</th>
              <th class="text-right">Exchange</th>

            </template>
            <template v-slot:default="row">
              <td>
                <div v-if="row.item.image" class="img-container">
                  <img :src="row.item.image" alt="..." />
                </div>
              </td>
              <td class="td-name pointer">
                <span @click="navigateToCompany(row.item.symbol)">
                  {{
                    row.item.ticker
                  }}</span>
              </td>

              <td>
                <span class="pointer" @click="navigateToCompany(row.item.symbol)">
                  <small>{{ row.item.company_name }}</small>
                </span>
              </td>
              <td class="td-number">
                {{ row.item.currency }}{{ row.item.price?.toFixed(2) }}
              </td>
              <td class="td-number">
                {{ row.item.change?.toFixed(2) }}
              </td>
              <td class="td-number">{{ row.item.change_pct?.toFixed(2) }}%</td>

              <td class="td-number">
                <small>{{ row.item.current_open?.toFixed(2) }}</small>
              </td>
              <td class="td-number">
                <small>{{ row.item.day_low?.toFixed(2) }}</small>
              </td>
              <td class="td-number">
                <small>{{ row.item.day_high?.toFixed(2) }}</small>
              </td>

              <td class="td-name pointer">
                <span @click="navigateToExchange(row.item.exchange, row.item.ticker)">
                  <span class="pull-right">
                    {{
                      row.item.exchange
                    }}</span>
                </span>
              </td>

            </template>
          </n-table>
          <span v-if="user.my_debug_interface" class="pull-right" style='position:absolute; right: 15px; bottom:10px'>
            <a v-if="apiCall" :href="getDebugApiEntry()" target="_blank">
              &nbsp;&nbsp;<i class="fa fa-gear"></i> Open API </a>
          </span>
        </div>
      </vnud-card>
    </div>
  </div>
</template>

<script>
import globalController from "@/globalController.js";
import globalConfig from "@/globalConfig.js";
import utils from "@/globalUtil.js";
import { mapState } from "vuex";

import {
  VnudCard,
  Table as NTable
} from "@/components";

export default {
  props: {
    symbol: {
      type: String,
      default: () => {
        return "default";
      },
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
  },
  components: { VnudCard, NTable, },
  created() {
    console.log(" Component creation ")
    this.loadData();

  },
  data() {
    return {
      apiCall: null,
      counter: 0,
      intervalId: null,
      isLoaded: false,
      isFirstLoaded: false,
      watchListMap: null,
      watchListTable: [

      ],

    };
  },
  mounted() {
    // Set up the interval when the component is mounted
    this.intervalId = setInterval(() => {
      this.counter += 1;
      this.loadData(this.watchlist);
    }, 1000 * 30);
  },
  unmounted() {    
    // Clear the interval when the component is destroyed
    if (this.intervalId) {
      clearInterval(this.intervalId); // Prevent memory leaks by clearing the interval
    }
  },
  methods: {
    getDebugApiEntry() {
      return globalConfig.getApiPath(this.apiCall);
    },
    navigateToExchange(exchange, symbol) {
      this.$router.push("/company/explore?exchange=" + exchange + "&symbol=" + symbol);
    },
    navigateToCompany(symbol) {
      this.$router.push("/company/view?symbol=" + symbol);
    },

    async loadData() {
      this.isLoaded = false;

      this.apiCall = "/company/financials/get/" + encodeURIComponent(this.symbol);
      globalController.api_call(
        this.apiCall,
        (result) => {
          const itemMap = {};
          this.watchListTable.forEach((item, index) => {
            itemMap[item.symbol] = index;
          });

          this.isLoaded = true;
          this.isFirstLoaded = true;
          for (var x = 0; x < result.exchange_tickers.length; x++) {
            let ticker = result.exchange_tickers[x];

            let data = null;

            if (result.financials) {
              data = result.financials[ticker];
              if (!data)
                continue;
              
              data['symbol'] = ticker;

              if (Object.prototype.hasOwnProperty.call(itemMap, ticker)) {
                // If the item exists, update its properties directly
                const existingItem = this.watchListTable[itemMap[ticker]];
                for (const key in data) {
                  if (Object.prototype.hasOwnProperty.call(existingItem, key)) {
                    existingItem[key] = data[key]; // Update the property value
                  }
                }

                //existingItem['company_name'] = data['company_name'] + " " + this.counter;
                continue;
              } else {
                // DELETE ITEM FROM VIEW
              }

              let p = ticker.split(":");
              data['ticker'] = p[1];
              data['exchange'] = p[0];

              let open = data['current_open'];
              if (open) {
                data['change'] = data['price'] - open;
                data['change_pct'] = ((data['price'] - open) / open) * 100;
              }
            }

            if (!data)
              data = {
                symbol: ticker,
                ticker: "",
                exchange: "",
                image: "",
                company_name: "N/A",
                price: 0,
                currency: "",
                open: 0,
                change: 0,
                change_pct: 0,
                day_low: 0,
                day_high: 0,
              };

            this.watchListTable.push(data)
          }
        },
        (error) => {
          console.log("Failed loading: Error loading watchlist");
          utils.runToast(
            ` ${error} `,
            "now-ui-icons ui-1_bell-53",
            "bottom-right",
            "danger"
          );
        }
      );
    },
  },
}
</script>


<style>
.action_button {
  margin-right: 10px;
}
</style>
