<template>
  <Form @submit="onSubmit" :validation-schema="schema">
    <div class="row">


      <div class="col-lg-12 mr-auto">
        <vnud-card class="card-signup text-center" no-footer-line>
          <template v-slot:header>
            <h4 class="card-title">Contact Us</h4>
          </template>

          <vee-input name="name" type="text" placeholder="Your Name..."
            addon-left-icon="now-ui-icons users_circle-08" />
          <vee-input name="email" type="text" placeholder="Your Email..."
            addon-left-icon="now-ui-icons ui-1_email-85" />
          <vee-input name="subject" type="text" placeholder="Subject..."
            :value="subject"
            addon-left-icon="now-ui-icons text_caps-small" />
          <vee-input name="message" type="textarea" placeholder="Your Message..."
            :value="message"
            addon-left-icon="now-ui-icons ui-1_send" />

          <template v-slot:footer>
            <n-button type="primary" native-type="submit" round size="lg">
              Send Message
            </n-button>
          </template>
        </vnud-card>
      </div>
    </div>
  </Form>
</template>

<script>
import globalConfig from "@/globalConfig.js";
import utils from "@/globalUtil.js";
import { ref, getCurrentInstance } from "vue";
import { Checkbox, VeeInput } from "@/components";
import { Form } from "vee-validate";
import * as Yup from "yup";

export default {
  name: "ContactForm",
  components: {
    Checkbox,
    VeeInput,
    Form,
  },
  data() {
    return {
      subject: '', // To hold the subject from the query params
      message: '', // To hold the body from the query params
    };
  },  
  created() {
    const url = window.location.href;
    const hash = url.split('#')[1];
    const queryString = hash.split('?')[1];
    const params = new URLSearchParams(queryString);    
    this.subject = params.get('subject') || ''; // Get subject or default to empty
    this.message = params.get('body') || ''; // Get body or default to empty
  },  
  setup() {
    const { proxy } = getCurrentInstance();

    function onSubmit(values) {
      fetch(globalConfig.getApiPath("/contact/submit"), { // Adjust the API endpoint as needed
        method: "post",
        mode: "cors",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: values.name,
          email: values.email,
          subject: values.subject,
          message: values.message,
        }),
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.status !== "success") {
          utils.runToast(
            `${data.error_msg}`,
            "now-ui-icons ui-1_bell-53",
            "bottom-right",
            "danger"
          );
          return;
        }
        utils.runToast(
          "Your message has been sent successfully!",
          "now-ui-icons ui-1_check",
          "bottom-right",
          "success"
        );
      })
      .catch((error) => {
        console.log(error);
        utils.runToast(
          "There was a problem accessing the server, please try again later.",
          "now-ui-icons ui-1_bell-53",
          "bottom-right",
          "danger"
        );
      });
    }

    const schema = Yup.object().shape({
      name: Yup.string().required().label("Your Name"),
      email: Yup.string().email().required().label("Your Email"),
      subject: Yup.string().required().label("Subject"),
      message: Yup.string().required().label("Message"),
    });

    return {
      onSubmit,
      schema,
    };
  },
};
</script>

<style>
/* Add your styles here */
</style>
